var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.previewDocs ? _c('div', {
    staticClass: "ui-file wrapper",
    class: _vm.mode
  }, [_vm.tip ? _c('div', {
    staticClass: "ui-file__tip form-block__tip"
  }, [_c('UiTip', {
    attrs: {
      "message": _vm.tip.message
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "upload-block",
    class: _vm.uploadClasses,
    on: {
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "drop": _vm.drop
    }
  }, [_c('div', {
    staticClass: "ui-file__header"
  }, [_c('span', {
    staticClass: "upload-block-title"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "ui-file__container"
  }, [_vm.showSubtitle ? _c('span', {
    staticClass: "upload-block-subtitle"
  }, [_vm._v(_vm._s(_vm.subtitle))]) : _vm._e(), _vm.isDragging ? _c('span', {
    staticClass: "upload-block-subtitle"
  }, [_vm._v("Отпустите для загрузки")]) : _vm._e(), _vm.mode === 'edit' ? _c('div', [_c('input', {
    ref: "fileInput",
    staticClass: "custom-file-input",
    attrs: {
      "type": "file",
      "multiple": _vm.multiple,
      "accept": _vm.effectiveAccept
    },
    on: {
      "change": _vm.onInput
    }
  }), _c('UiButton', {
    attrs: {
      "disabled": _vm.disabled,
      "prepend-icon": "icons-upload",
      "type": "secondary",
      "label": "Загрузить файл",
      "pending": _vm.pendingButton
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.fileCtrlClick.apply(null, arguments);
      }
    }
  }, [_vm._v(" Загрузить файл ")])], 1) : _vm._e(), _vm.mode === 'edit' ? _c('span', {
    staticClass: "input-tips"
  }, [_vm._v(" " + _vm._s(_vm.tips) + " ")]) : _vm._e(), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasFiles,
      expression: "hasFiles"
    }],
    staticClass: "preview-list"
  }, [_vm._l(_vm.fileArr, function (fileObj, fileIdx) {
    return [_vm.preview ? _c('img', {
      key: ("img#" + fileIdx),
      staticClass: "img-preview",
      attrs: {
        "src": fileObj.link,
        "height": _vm.height,
        "width": _vm.width,
        "alt": "Предпросмотр изображения",
        "title": "Предпросмотр изображения"
      }
    }) : _vm._e(), _c('li', {
      key: ("li#" + fileIdx)
    }, [_c('a', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: fileObj.link,
        expression: "fileObj.link"
      }],
      staticClass: "preview-list__file-name",
      class: {
        'item-link__download': _vm.isDownload(fileObj)
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.downloadFile(fileObj);
        }
      }
    }, [_vm._v(" " + _vm._s(fileObj.file ? fileObj.file.name : fileObj.name) + " ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !fileObj.link,
        expression: "!fileObj.link"
      }],
      staticClass: "preview-list__file-name"
    }, [_vm._v(" " + _vm._s(fileObj.file ? fileObj.file.name : fileObj.name) + " ")]), _vm.mode === 'edit' ? _c('div', {
      staticClass: "remove-file",
      on: {
        "click": function ($event) {
          return _vm.onRemove(fileIdx);
        }
      }
    }) : _vm._e()])];
  })], 2), _vm.mode === 'view' ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasFiles,
      expression: "!hasFiles"
    }]
  }, [_vm.multiple ? _c('div', [_vm._v(" Файлы не загружены ")]) : _c('div', [_vm._v(" Файл не загружен ")])]) : _vm._e(), _vm.mode === 'edit' ? _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.error,
      expression: "error"
    }],
    staticClass: "input-caption input-caption__error"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()])])]) : _vm.fileArr.length ? _c('div', {
    staticClass: "preview-docs"
  }, [_c('h3', {
    staticClass: "preview-docs__title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('ul', {
    staticClass: "preview-docs__list"
  }, _vm._l(_vm.fileArr, function (doc, fileIdx) {
    return _c('li', {
      key: doc.documentId,
      staticClass: "preview-docs__item"
    }, [doc.legalDoc ? _c('a', {
      staticClass: "preview-docs__item-link",
      attrs: {
        "target": "_blank",
        "href": _vm.documentViewRoute(doc)
      }
    }, [_vm._t("docName", function () {
      return [_vm._v(" " + _vm._s(doc.file.name) + " ")];
    }, null, {
      doc: doc
    })], 2) : _c('a', {
      staticClass: "preview-docs__item-link",
      class: {
        'item-link__download': _vm.isDownload(doc)
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.downloadFile(doc);
        }
      }
    }, [_vm._t("docName", function () {
      return [_vm.displayName ? _c('p', [_vm._v(_vm._s(_vm.displayName))]) : _c('p', [_vm._v(_vm._s(doc.file.name))])];
    }, null, {
      doc: doc
    })], 2), _vm.mode === 'edit' ? _c('div', {
      staticClass: "preview-docs__remove-file",
      on: {
        "click": function ($event) {
          return _vm.onRemove(fileIdx);
        }
      }
    }) : _vm._e()]);
  }), 0), _c('input', {
    ref: "fileInput",
    staticClass: "preview-docs__file-input",
    attrs: {
      "type": "file",
      "multiple": _vm.multiple,
      "accept": _vm.effectiveAccept
    },
    on: {
      "change": _vm.onInput
    }
  }), _vm.mode === 'edit' ? _c('UiButton', {
    staticClass: "preview-docs__add-btn",
    attrs: {
      "label": "Добавить документ",
      "type": "secondary",
      "prependIcon": "add-icon",
      "size": "xs",
      "pending": _vm.pendingButton
    },
    on: {
      "click": _vm.fileCtrlClick
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }