import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';
import { InvestProjectBuilder, PitchBuilder } from './ProjectBuilder';
import cookieJs from 'cookie_js';

export const PAYMENT_SCHEMES_CODES = {
	END_OF_TERM_PAYMENT: 'END_OF_TERM_PAYMENT',
	ANNUITY_PAYMENT: 'ANNUITY_PAYMENT',
	INTEREST_MONTHLY_DEBT_END_OF_TERM_PAYMENT: 'INTEREST_MONTHLY_DEBT_END_OF_TERM_PAYMENT',
	DIFFERENTIAL_PAYMENT: 'DIFFERENTIAL_PAYMENT'
}

export const RATE_TYPES_CODES = {
	FIXED: 'FIXED',
	FLOATING: 'FLOATING',
}

export const FLOATING_RATE_SCHEMES = [
	PAYMENT_SCHEMES_CODES.DIFFERENTIAL_PAYMENT,
	PAYMENT_SCHEMES_CODES.END_OF_TERM_PAYMENT,
	PAYMENT_SCHEMES_CODES.INTEREST_MONTHLY_DEBT_END_OF_TERM_PAYMENT,
];

function _bindProject(project, action) {
	return new Promise( (resolve) => {
		resolve(new InvestProjectBuilder({ buildAction: action })
			.projectInfo(project)
			.investType(project.type, project)
			.team(project.team)
			.documents(project.documents)
			.founderDetails(project.founderDetails)
			.disclosureInformation(project.disclosureInformation)
			.build());
	})
}

function _bindPitch(pitch, action) {
	return new Promise( (resolve) => {
		resolve(new PitchBuilder({ buildAction: action })
			.projectInfo(pitch)
			.documents(pitch.documents)
			.build()
		);
	})
}

export function getProject(type, id) {
	return getApiRequestBuilder(`${type}/${id}`).send();
}

export function getPitch(id) {
	return getApiRequestBuilder(`pitches/${id}`).send();
}

export function getPublicProject(type, id) {
	return getApiRequestBuilder(`${type}/public/${id}`).public.send();
}

export function showInterest(type, id, rangeId, enabled = true) {
	return putApiRequestBuilder(`${type}/${id}/interest`)
		.send({ enabled, rangeId });
}

export function followProject(type, id, enabled = true) {
	return putApiRequestBuilder(`${type}/${id}/follow`)
		.send({ enabled });
}

export function sendProjectReport(projectId, reportDocument) {
	return postApiRequestBuilder(`projects/${projectId}/report`).send(reportDocument)
}

export function sendOtherDocs(projectId, otherDocs = []) {
	return putApiRequestBuilder(`projects/${projectId}/add-other-docs`).send(otherDocs)
}

export function createProject(project) {
	const action = (data) => postApiRequestBuilder(`projects`).send(data);
	return _bindProject(project, action)
}

export function editProject(type, project) {
	const action = (data) => putApiRequestBuilder(`${type}`).send(data);
	return _bindProject(project, action)
}

export function createDraftProject(project) {
	const action = (data) => postApiRequestBuilder(`projects/draft`).send(data);
	return _bindProject(project, action)
}

export function editDraftProject(project) {
	const action = (data) => putApiRequestBuilder(`projects/draft`).send(data);
	return _bindProject(project, action)
}

export function createPitch(pitch) {
	const action = (data) => postApiRequestBuilder(`pitches`).send(data);
	return _bindPitch(pitch, action);
}

export function editPitch(pitch) {
	const action = (data) => putApiRequestBuilder(`pitches`).send(data);
	return _bindPitch(pitch, action)
}

export function createDraftPitch(pitch) {
	const action = (data) => postApiRequestBuilder(`pitches/draft`).send(data);
	return _bindPitch(pitch, action)
}

export function editDraftPitch(pitch) {
	const action = (data) => putApiRequestBuilder(`pitches/draft`).send(data);
	return _bindPitch(pitch, action)
}

export function changeStatus(projectId, status) {
	if (!/(DRAFT|PREPARED|PUBLISHED|REJECTED|COLLECTED|CANCELED|RELEASED|CLOSED)/.test(status)) throw new Error('invalid status');
	return putApiRequestBuilder(`projects/${projectId}/status/${status}`).send();
}

export function deleteProject(id) {
	return deleteApiRequestBuilder(`projects/${id}`).send();
}

export function deletePitch(id) {
	return deleteApiRequestBuilder(`pitches/${id}`).send();
}

export function refuseProject(id) {
	return postApiRequestBuilder(`projects/${id}/refuse`).send();
}

export function collectProject(id) {
	return postApiRequestBuilder(`projects/${id}/collect`).send();
}

export function getProjectsMine() {
	return getApiRequestBuilder(`projects/mine`).send();
}

export function getProjectsMineDraft() {
	return getApiRequestBuilder(`projects/mine/draft`).send();
}

export function getPitchesMine() {
	return getApiRequestBuilder(`pitches/mine`).send();
}

export function getRenderedTemplateOffer(id) {
	return getApiRequestBuilder(`projects/${id}/render`).send();
}

export function saveCollateral(collateral) {
	return postApiRequestBuilder(`projects/collateral`).send(collateral);
}

export function getTaxProject({ target, term, projectType, participantId }) {
	return getApiRequestBuilder(`projects/tax`)
		.query(Object.entries({ target, term, projectType, participantId }))
		.send();
}

export function getProjectInvestments({ participantId, projectId }) {
	return getApiRequestBuilder(`projects/${projectId}/investments`)
		.query(Object.entries({ participantId }))
		.send();
}

export function getPreInvestments({ projectId }) {
	return getApiRequestBuilder(`projects/${projectId}/pre-investments`)
		.send();
}

/* Информация о текущей задолженности по займу */
export function getProjectLoanDebt(projectId) {
	return getApiRequestBuilder(`projects/${projectId}/loan-debt`).send();
}

/* Метод гашения займов */
export function repaymentLoan(projectId, earlyTotalDebt, earlyRepayment = false) {
	return postApiRequestBuilder(`projects/${projectId}/repayment`).send({ earlyTotalDebt, earlyRepayment });
}

/* График выплат по займу */
export function getInvestmentPaymentShedule(projectId, investmentId) {
	return getApiRequestBuilder(`projects/${projectId}/payment-schedule/investments/${investmentId}`).send();
}

/* Обновить акционеров проекта и получить новое акционерное соглашение */
export function updateShareholders(projectId, shareholders, stopRender) {
	return putApiRequestBuilder(`projects/${projectId}/update-shareholders`).send({ shareholderDtoList: shareholders, stopRender: stopRender });
}

export function getConversionProgressByInvestor(projectId, investorId) {
	return getApiRequestBuilder(`conv-loan/${projectId}/progress`)
		.query(Object.entries({ investorId }))
		.send()
		.then(res => res.data);
}

export function getConversionProgress(projectId) {
	return getApiRequestBuilder(`conv-loan/${projectId}/progress-conversion`)
		.send()
		.then(res => res.data);
}

export function getConversionRepaymentProgress(projectId) {
	return getApiRequestBuilder(`conv-loan/${projectId}/progress-repayment`)
		.send()
		.then(res => res.data);
}

/* Запросить конвертацию Конверт. займа */
export function requestConversion(requestData) {
	return postApiRequestBuilder(`conv-loan/request-conversion`)
		.send(requestData);
}

/* Получить рассчеты для запроса на конвертацию */
export function conversionInvestorProps(projectId, requestData) {
	let query = [['investorId', requestData.investorId], ['evalType', requestData.evalType || null]];
	return getApiRequestBuilder(`conv-loan/${projectId}/investor-props`)
		.query(query)
		.send();
}

/* Запросить конвертацию Конверт. займа */
export function repayConvLoan(projectId, amount) {
	return postApiRequestBuilder(`conv-loan/${projectId}/repay`)
		.send({ amount });
}

/* Запросить возврат средств конвертацию Конверт. займа */
export function requestRefund(projectId, investorId) {
	return postApiRequestBuilder(`conv-loan/${projectId}/request-refund`)
		.query(Object.entries({ investorId }))
		.send();
}

export function confirmConversion(projectId, investorIdList = []) {
	return postApiRequestBuilder(`conv-loan/request/confirm`)
		.send({projectId, investorIdList});
}

export function rejectConversion(projectId, investorIdList = []) {
	return postApiRequestBuilder(`conv-loan/request/reject`)
		.send({projectId, investorIdList});
}

export function convLoanRequestRetry(projectId, investorId) {
	return putApiRequestBuilder(`admin/conv-loan/${projectId}/request-retry`)
		.query(Object.entries({ investorId }))
		.send();
}

/* Конвертация Займов в Акции */
export function putConversationRequest(projectId) {
	return putApiRequestBuilder(`projects/${projectId}/convert`).send();
}

/* Акций получено */
export function setInvestmentSharesReceived(projectId, investmentIds) {
	return postApiRequestBuilder(`projects/${projectId}/investments/received-stock`).send({ investorIds: investmentIds });
}

/* Обновить результаты зачисления акций */
export function updateCreditingShares(projectId, reports) {
	return putApiRequestBuilder(`projects/${projectId}/crediting-shares`).send({ projectId, reports });
}

/* Установить внешний счёт для зачисления акций */
export function saveShareholderAccount(projectId, investmentId, shareholderAccount) {
	return postApiRequestBuilder(`projects/${projectId}/investments/${investmentId}/shareholder-account`).send({ shareholderAccount });
}

/* Получить сводку об инвестиции по инвестору и проекту */
export function getInvestorSummry(projectId, participantId) {
	return getApiRequestBuilder(`projects/${projectId}/investor-summary`)
		.query(Object.entries({ participantId }))
		.send()
		.then(res => res.data);
}

export function addProjectView(projectId, username) {
	const keyCookieViewProject = 'invest-project-' + projectId + '-' + username;
	const cookie = cookieJs.get(keyCookieViewProject)
	if (!cookie) {
		putApiRequestBuilder(`projects/${projectId}/add-view`).send().finally(() => {
			cookieJs.set(keyCookieViewProject, Date.now(), { expires: 1 });
		});
	}
}

export function addPitchView(pitchId, username) {
	const keyCookieViewProject = 'invest-project-' + pitchId + '-' + username;
	const cookie = cookieJs.get(keyCookieViewProject)
	if (!cookie) {
		putApiRequestBuilder(`pitches/${pitchId}/add-view`).send().finally(() => {
			cookieJs.set(keyCookieViewProject, Date.now(), { expires: 1 });
		});
	}
}

export function notifyNotVerifyOperation(projectId) {
	return postApiRequestBuilder(`projects/${projectId}/notify-not-verify`).send();
}
