<template>
	<div class="form">
		<template v-for="(row, rowIdx) in fieldsRows">
			<div
				v-show="_fieldsInRow(row).some((fld) => _isFieldVisible(fld))"
				:key="`f${formId}-r${rowIdx}`"
				class="form__row"
				:style="_getRowStyle(row)"
			>
				<div
					v-for="(fld, fldIdx) in _fieldsInRow(row)"
					v-show="_isFieldVisible(fld)"
					:id="`form-col-${_getFieldProperty(fld, 'name')}`"
					:key="`f${formId}-r${rowIdx}-c${fldIdx}-n${_getFieldProperty(fld, 'name')}`"
					class="form__col"
					:class="fld.colClass"
					:style="_getColStyle(row, fldIdx)"
				>
					<UiInput
						v-if="fld.type==='string'"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:nowrap="_isTableListFormat()"
						:disabled="_isFieldDisabled(fld)"
						:maxLength="_getFieldPropertyFn(fld, 'maxLength')"
						:mask="_getFieldPropertyFn(fld, 'mask')"
						:size="_getFieldPropertyFn(fld, 'size')"
						:use-masked-value="_getFieldProperty(fld, 'useMaskedValue')"
						:clearable="_getFieldPropertyFn(fld, 'clearable', true)"
						:error="_getFieldError(fld)"
						:model-value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@emitValue="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() =>_onBlurField(fld)"
					/>
					<UiInput
						v-else-if="fld.type==='text'"
						type="textarea"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:resize="true"
						:maxLength="_getFieldPropertyFn(fld, 'maxLength')"
						:size="_getFieldPropertyFn(fld, 'size')"
						:nowrap="_isTableListFormat()"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldDisabled(fld)"
						:error="_getFieldError(fld)"
						:model-value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@emitValue="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiNumberInput
						v-else-if="/^int(eger)?$/.test(fld.type)"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldDisabled(fld)"
						:nowrap="_isTableListFormat()"
						:error="_getFieldError(fld)"
						:maxLength="_getFieldPropertyFn(fld, 'maxLength')"
						:limitValue="_getFieldPropertyFn(fld, 'limitValue')"
						:maxValue="_getFieldProperty(fld, 'maxValue', 0)"
						:fractions="0"
						:thousants="_getFieldProperty(fld, 'thousants', false)"
						:allowNegative="_getFieldProperty(fld, 'allowNegative', false)"
						:value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@input="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiNumberInput
						v-else-if="fld.type==='money'"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldDisabled(fld)"
						:nowrap="_isTableListFormat()"
						:error="_getFieldError(fld)"
						:maxLength="_getFieldPropertyFn(fld, 'maxLength')"
						:limitValue="_getFieldPropertyFn(fld, 'limitValue')"
						:maxValue="_getFieldProperty(fld, 'maxValue', 0)"
						:fractions="2"
						:thousants="_getFieldProperty(fld, 'thousants', true)"
						:allowNegative="_getFieldProperty(fld, 'allowNegative', false)"
						:value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@input="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiNumberInput
						v-else-if="fld.type==='number'"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldDisabled(fld)"
						:nowrap="_isTableListFormat()"
						:error="_getFieldError(fld)"
						:fractions="_getFieldProperty(fld, 'fractions', 0)"
						:thousants="_getFieldProperty(fld, 'thousants', true)"
						:maxLength="_getFieldPropertyFn(fld, 'maxLength')"
						:limitValue="_getFieldPropertyFn(fld, 'limitValue')"
						:maxValue="_getFieldProperty(fld, 'maxValue', 0)"
						:allowNegative="_getFieldProperty(fld, 'allowNegative', true)"
						:value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@input="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiDate
						v-else-if="fld.type==='date'"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldDisabled(fld)"
						:nowrap="_isTableListFormat()"
						:error="_getFieldError(fld)"
						:value="_getObjVal(fld)"
						@change="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiInput
						v-else-if="fld.type==='password'"
						type="password"
						:label="_getFieldLabel(fld)"
						:placeholder="_getFieldPropertyFn(fld, 'placeholder')"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldDisabled(fld)"
						:clearable="_getFieldPropertyFn(fld, 'clearable', true)"
						:error="_getFieldError(fld)"
						:model-value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@emitValue="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() =>_onBlurField(fld)"
					/>
					<UiButton
						v-else-if="fld.type ==='button'"
						style="flex:inherit"
						:label="_getFieldLabel(fld)"
						:disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
						:size="_getFieldProperty(fld, 'size')"
						:type="_getFieldProperty(fld, 'btnType')"
						:block="_getFieldProperty(fld, 'block')"
						:prependIcon="_getFieldPropertyFn(fld, 'prependIcon')"
						:prependIconColor="_getFieldPropertyFn(fld, 'prependIconColor')"
						:appendIcon="_getFieldPropertyFn(fld, 'appendIcon')"
						:appendIconColor="_getFieldPropertyFn(fld, 'appendIconColor')"
						:pending="_getFieldPropertyFn(fld, 'pending')"
						:href="_getFieldPropertyFn(fld, 'href')"
						:to="_getFieldPropertyFn(fld, 'to')"
						:error="_getFieldError(fld)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
						@click="() => _onClick(fld)"
					/>
					<UiCheckbox
						v-else-if="fld.type ==='boolean'"
						:label="_getFieldLabel(fld)"
						:description="_getFieldPropertyFn(fld, 'hint')"
						:disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
						:error="_getFieldError(fld)"
						:true-value="_getFieldProperty(fld, 'trueValue', true)"
						:false-value="_getFieldProperty(fld, 'falseValue', false)"
						:model-value="_getObjVal(fld)"
						@change="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiCheckboxSwitch
						v-else-if="fld.type ==='switch'"
						:label="_getFieldLabel(fld)"
						:description="_getFieldPropertyFn(fld, 'hint')"
						:disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
						:error="_getFieldError(fld)"
						:true-value="_getFieldProperty(fld, 'trueValue', true)"
						:false-value="_getFieldProperty(fld, 'falseValue', false)"
						:model-value="_getObjVal(fld)"
						@change="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiSelectNew
						v-else-if="fld.type==='select'"
						:label="_getFieldLabel(fld)"
						:unselected-title="_getFieldPropertyFn(fld, 'placeholder')"
						:hint="_getFieldPropertyFn(fld, 'hint')"
						:readonly="_isFieldReadonly(fld)"
						:nowrap="_isTableListFormat()"
						:disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
						:options="_getDataByProvider(fld, 'options')"
						:multiple="_getFieldPropertyFn(fld, 'multiple', false)"
						:error="_getFieldError(fld)"
						:value="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@change="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiRadioGroup
						v-else-if="fld.type==='radio'"
						:label="_getFieldLabel(fld)"
						:hint="_getFieldPropertyFn(fld, 'hint')"
						:horizontal="_getFieldPropertyFn(fld, 'horizontal')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
						:options="_getDataByProvider(fld, 'options')"
						:error="_getFieldError(fld)"
						:value="_getObjVal(fld)"
						@change="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiQuillEditor
						v-else-if="fld.type==='htmleditor'"
						:label="_getFieldLabel(fld)"
						:caption="_getFieldPropertyFn(fld, 'hint')"
						:size="_getFieldPropertyFn(fld, 'size')"
						:readonly="_isFieldReadonly(fld)"
						:disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
						:error="_getFieldError(fld)"
						:value="_getObjVal(fld)"
						@change="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
					/>
					<UiFile
						v-else-if="fld.type==='file'"
						:title="_getFieldLabel(fld)"
						:error="_getFieldError(fld)"
						:subtitle="_getFieldPropertyFn(fld, 'hint')"
						:tips="_getFieldPropertyFn(fld, 'placeholder')"
						:accept="_getFieldPropertyFn(fld, 'accept', '')"
						:accept-types="_getFieldPropertyFn(fld, 'acceptTypes', '')"
						:mode="_isFieldReadonly(fld) ? 'view' : 'edit'"
						:preview="_getFieldPropertyFn(fld, 'preview', false)"
						:width="_getFieldPropertyFn(fld, 'width')"
						:height="_getFieldPropertyFn(fld, 'height')"
						:multiple="_getFieldPropertyFn(fld, 'multiple', false)"
						:upload-handler="_getFieldProperty(fld, 'uploadHandler', null)"
						:delete-handler="_getFieldProperty(fld, 'deleteHandler', null)"
						:prop-mapper="_getFieldProperty(fld, 'propMapper', null)"
						:event-mapper="_getFieldProperty(fld, 'eventMapper', null)"
						:mix-data="_getFieldProperty(fld, 'mixData', null)"
						:files="_getObjVal(fld)"
						:tip="_getFieldTip(fld)"
						@changeValue="(val) => _setObjVal(fld, val)"
						@focus="() => _onFocusField(fld)"
						@blur="() => _onBlurField(fld)"
						@uploaded="_onFileUploaded"
					/>
					<UiArrayInput
						v-else-if="fld.type==='array'"
						:value="_getObjVal(fld, [])"
						:error="_getFieldError(fld)"
						:label="_getFieldLabel(fld)"
						:addModalTitle="_getFieldPropertyFn(fld, 'addModalTitle')"
						:updateModalTitle="_getFieldPropertyFn(fld, 'updateModalTitle')"
						:buttonLabel="_getFieldPropertyFn(fld, 'buttonLabel')"
						:columns="_getFieldPropertyFn(fld, 'columns')"
						:fields="_getFieldProperty(fld, 'fields')"
						:readonly="_getFieldPropertyFn(fld, 'readonly')"
						:tipsProviderKey="_getFieldPropertyFn(fld, 'tipsProviderKey')"
						@change="(val) => _setObjVal(fld, val)"
					>
						<template
							v-for="slotName in _getInnerSlotNames(fld.name)"
							v-slot:[slotName]="slotAttrs"
						>
							<slot :name="`${fld.name}:${slotName}`" v-bind="slotAttrs"/>
						</template>
					</UiArrayInput>
					
					<div
						v-else-if="fld.type==='table'"
					>
						<div v-show="_getFieldLabel(fld)" class="form__table__label">{{ _getFieldLabel(fld) }}</div>
						<div class="form__table__frame" :class="_hasFieldError(fld) ? 'form__table__frame__error' : ''">
							<UiTable
								:ref="`table#${fld.name}`"
								:columns="_getDataByProvider(fld, 'columns')"
								:items="_getDataByProvider(fld, 'items') ?? []"
							>
								<template
									v-for="slotName in _getInnerSlotNames(fld.name)"
									v-slot:[slotName]="slotAttrs"
								>
									<slot :name="`${fld.name}:${slotName}`" v-bind="slotAttrs"/>
								</template>
							</UiTable>
						</div>
						<div v-if="_hasFieldError(fld)" class="form__table__error">
							{{ _getFieldError(fld) }}
						</div>
					</div>

					<div v-else-if="fld.type==='subtitle'">
						<h4
							:ref="`subt#${fld.name}`"
							class="form__subtitle"
						>
							{{ _getFieldLabel(fld) }}
						</h4>
					</div>

					<UiBlock
						v-else-if="fld.type==='block'"
						:title="_getFieldLabel(fld)"
						:tip="_getFieldTip(fld)"
						:showVisibilityToggle="_getFieldProperty(fld, 'showVisibilityToggle')"
						:visible="_getFieldPropertyFn(fld, 'visible')"
					>
						<UiForm
							:ref="`form#${fld.name}`"
							:fields="_getFieldProperty(fld, 'fields', [])"
							:data="dataObj"
							:disableErrorScroll="disableErrorScroll"
							:name="_getHierarchyFieldName(fld.name)"
							:tips-provider="_getHierarchyTipsProvider(fld.name)"
							:prepare-errors="prepareErrors"
							@change="_setObj"
							@focus="fld0 => _onFocusField(fld0)"
							@blur="fld0 => _onBlurField(fld0)"
							@onFileUploaded="_onFileUploaded"
						>
							<template
								v-for="slotName in _getInnerSlotNames(fld.name)"
								v-slot:[slotName]="slotAttrs"
							>
								<slot :name="`${fld.name}:${slotName}`" v-bind="slotAttrs"/>
							</template>
						</UiForm>
					</UiBlock>
				
					<div
						v-else-if="fld.type==='slot'"
					>
						<slot 
							:name="fld.name"
							:data="dataObj"
							:error="_getFieldError(fld)" 
							:errorsObj="errorsObj" 
						/>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { getValue, randomKey, setValue, compareObjects } from "@/utils/common";
import { ofRequired, ofRules } from "@/utils/validation/wrapper"

export default {
	name: 'UiForm',
	components: { 
		UiNumberInput: () => import('@/components/UI/UiNumberInput/UiNumberInput.vue'),
		UiSelectNew: () => import('@/components/UI/UiSelectNew/UiSelectNew.vue'),
		UiQuillEditor: () => import('@/components/UI/UiQuillEditor/UiQuillEditor.vue'),
	},
	inject: { scope: { default: null } },

	/**
	 * Событие изменения данных: change. В аргументе события - объект данных
	 * 
	 * Событие фокуса (focus) и потери фокуса (blur) контролом поля. В аргументе - объект описания поля (FormField).
	 * 
	 * Именованые слоты передаются для полей типа slot текущего уровня.
	 * Либо для вложенных полей типа slot, например в блоках. В этом случае имя слота имеет формат <имя блока>:<имя слота>.
	 * Вложенность может быть многоуровневой.
	 * А также, в качестве слотов для ячеек таблицы, указанной в полях типа table. В этом случае имя слота должно быть <имя таблицы>:<имя слота>
	 */	
	model: {
		prop: 'data',
		event: 'change',
	},

	props: {
		/**
		 * Имя формы.
		 * На верхнем уровне не требуется. Служит для корректного постороения имен объектов во вложенных формах.
		 */
		name: {
			type: String,
			default: "",
		},
		/**
		 * Данные формы.
		 * Объект, свойства которого биндятся с контролами формы.
		 */
		data: {
			type: Object,
			required: true,
		},
		/**
		 * Поля формы.
		 * Массив, определяющий контролы формы, их тип, данные с которыми они связаны, их расположение и т.п.
		 * Массив определяется типом FieldsArray (см. fields.d.ts)
		 */
		fields: {
			type: Array,
			required: true,
		},
		/**
		 * Провайдер подсказок формы.
		 * Интерфейс провайдела аналогичен провайдерам данных - см. DataProvider
		 * Если указан, то при загрузке формы извлекаются подсказки для всех полей. Поля вложенных форм адресуются аналогично слотам (см. корневой коммент)
		 */
		tipsProvider: {
			type: [ Function, Array ],
		},

		/** 
		 * Отключает скроллинг формы к первому полю с ошибкой при неуспешной валидации
		 */
		disableErrorScroll: {
			type: Boolean,
			default: false,
		},

		/**
		 * UiTableList формат, нужен чтобы задавать определенные стили фильтрам в таблице
		 */
		tableListFormat: {
			type: Boolean,
			default: false,
		},

		/**
		 * Внесение ошибок снаружи
		 */
		prepareErrors: {
			type: Object,
			default: null,
		}
	},

	data: function() {
		return {
			formId: randomKey(""),
			dataObj: {},
			dataProviders: {},
			errorsObj: {},
			isAlreadyValidated: false
		}
	},

	computed: {
		fieldsRows() {
			return this.fields
				.map(ff => Array.isArray(ff) ? ff : [ff]);
		},
		flatFields() {
			return this.fields
				.flatMap(ff => ff);
		},
		tips() {
			let tipMap = {};
			if (this.dataProviders.__tips) {
				tipMap = Object.fromEntries(
					this.dataProviders.__tips
						.map(t => [t.code, t.text])
				);
			}
			return tipMap;
		},
	},

	watch: {
		data: {
			handler(value) {
				this.dataObj = { ...value };
			},
			deep: true
		},
		tipsProvider(tips) {
			this._setProviderData(tips, val => this.$set(this.dataProviders, "__tips", val));
		},
		prepareErrors(prepareErrors) {
			this._clearErrors();

			if (prepareErrors) {
				this.errorsObj = {
					...prepareErrors
				};
			}
		},
	},

	created() {
		if (!compareObjects(this.data, this.dataObj)) {
			this.dataObj = { ...this.data };
		}
		this._setTips();

		if (this.prepareErrors) {
			this.errorsObj = {
				...this.errorsObj,
				...this.prepareErrors
			};
		}
	},

	methods: {
		forEachSubform(callback) {
			this.flatFields
				.filter(ff => ff.type === 'block')
				.flatMap(ff => this.$refs[`form#${ff.name}`])
				.forEach(callback);
		},
		_setTips() {
			const tips = this.tipsProvider;
			if (tips?.length) {
				this._setProviderData(tips, val => this.$set(this.dataProviders, "__tips", val));
			}
		},
		// Fields
		_fieldsInRow(row) {
			return row.map(fld => ({ type: "string", ...fld }));
		},
		_getHierarchyFieldName(fieldName) {
			if (this.name) {
				return this.name + ":" + fieldName;
			}
			return fieldName;
		},
		_getHierarchyTipsProvider(fieldName) {
			const prefix = fieldName + ":";
			if (this.dataProviders.__tips)
				return this.dataProviders.__tips
					.filter(tip => tip.code.startsWith(prefix))
					.map(tip => ({ ...tip, code: tip.code.substr(prefix.length) }))
			return [];
		},

		_onFileUploaded(value) {
			const flag = value ? true : false;
			this.$emit("onFileUploaded", flag);
		},
		// Values
		_getObjVal(field, defaultValue = undefined) {
			if (field) {
				let val = undefined;
				if (field.getter) { // есть геттер
					val = field.getter(this.dataObj);
				} else { // извлечение по имени
					val = getValue(this.dataObj, field.name);
				}
				return val ?? defaultValue;
			}
			return defaultValue;
		},

		_setObjVal(field, value) {
			this._clearErrorsOnUpdate();
			if (field) {
				if (value === this._getObjVal(field)) {
					return;
				}
				this._clearFieldError(field);
				if (field.setter) { // есть сеттер
					field.setter(this.dataObj, value);
				} else { // запись по имени
					setValue(this.dataObj, field.name, value);
				}
				if (field.onChange && typeof field.onChange === "function") {
					field.onChange(field, value, this.dataObj);
				}
				this._emitChange();
			}
		},
		_setObj(value) {
			this._clearErrorsOnUpdate();
			this.$set(this.$data, "dataObj", value);
			this._emitChange();
		},

		_emitChange() {
			this.$emit("change", this.dataObj);
		},

		// Field properties
		_getFieldLabel(field) {
			if (field) {
				return this._getFieldPropertyFn(field, "label", "")
					+ (this._isFieldRequired(field) ? String.fromCharCode(160) + "*" : "");
			}
			return ""
		},

		_getFieldTip(field) {
			if (this.tips[field.name]) {
				return {
					message: this.tips[field.name],
				};
			} else {
				return null;
			}
		},

		_getFieldProperty(field, prop, def = undefined) {
			const val = field ? field[prop] : undefined;
			return val != undefined ? val : def;
		},
		_getFieldPropertyFn(field, prop, def = undefined) {
			let propVal = field ? field[prop] : undefined;
			if (propVal) {
				if (typeof propVal === "function") {
					propVal = propVal(this.dataObj);
				}
			}
			return propVal != undefined ? propVal : def;
		},

		_getColWidthPrc(row, idx) {
			const fld = row[idx];
			let fldCols = fld.gridCols;
			if (!fldCols) { // cols не задан - рассчитываем
				const flds = row.filter(f => this._isFieldVisible(f));
				const autoFields = flds.filter(f => !f.gridCols).length;
				const definedCols = flds.map(f => f.gridCols || 0).reduce((prev, curr) => prev + curr, 0);
				fldCols = (12 - definedCols) / autoFields;
			}
			return 100 * fldCols / 12;
		},
		_getColRightPrc(row, idx) {
			let sum = 0;
			for (let i = 0; i <= idx; i++) {
				sum += this._getColWidthPrc(row, i);
			}
			return sum;
		},
		_getTipWidthPx(row, idx) {
			const formWidth = this.$el?.clientWidth ?? 400;
			const rightPrc = this._getColRightPrc(row, idx);
			return Math.min(formWidth * rightPrc / 100 - 30, 600);
		},

		_getColStyle(row, idx) {
			let styles = [];
			// стили колонки
			styles.push(this._getFieldPropertyFn(row[idx], "colStyle", ""));

			return styles.filter(s => !!s).flat().join(";");
		},

		_getRowStyle(row) {
			let styles = [];

			let template = '';

			const length = row.filter(f => this._isFieldVisible(f)).length;

			if (length) {
				row.forEach((f, idx) => {
					const widthPrc = this._getColWidthPrc(row, idx);
					if (this._isFieldVisible(f) && widthPrc > 0) {
						template += ` ${widthPrc / length}fr`;

						if (f?.rowStyle) {
							styles.push(f.rowStyle);
						}
					}
				});

				styles.push(`grid-template-columns: ${template}`);
			}

			return styles.filter(s => !!s).flat().join(";");
		},

		_isFieldVisible(field) {
			if (field.hidden) {
				if (typeof field.hidden === "function") {
					return !field.hidden(this.dataObj);
				}
				return !field.hidden;
			}
			return true;
		},

		_isFieldRequired(field) {
			if (field.required) {
				if (typeof field.required === "function") {
					return field.required(field, this.dataObj);
				}
				return field.required;
			}
			return false;
		},
		_isFieldReadonly(field) {
			if (field.readonly) {
				if (typeof field.readonly === "function") {
					return field.readonly(this.dataObj);
				}
				return field.readonly;
			} else if (field.readonly === undefined) return false;
			return false;
		},
		_isFieldDisabled(field) {
			if (typeof field.disabled === 'boolean') {
				return field.disabled;
			}
			if (typeof field.disabled === "function") {
				return field.disabled(this.dataObj);
			}
			return false;
		},

		// Field events
		_onFocusField(field) {
			this.$emit("focus", { ...field, hierarchyName: this._getHierarchyFieldName(field.name) });
		},
		_onBlurField(field) {
			const value = this._getObjVal(field, '');
			if (['text', 'string'].includes(field.type) && value) {
				this._setObjVal(field, value.trim());
			}
			this.$emit("blur", { ...field, hierarchyName: this._getHierarchyFieldName(field.name) });
		},
		_onClick(field) {
			if (field.clickHandler && (typeof field.clickHandler === "function")) {
				field.clickHandler(field, this.dataObj);
			}
		},

		// Slots
		_getInnerSlotNames(containerName) {
			const names = Object.entries(this.$scopedSlots)
				.filter(ent => ent[0].startsWith(containerName + ":"))
				.map(ent => ent[0].substring(containerName.length + 1))
			return names;
		},

		// Data providers
		_setProviderData(provider, setter) {
			let result = null;
			if (typeof provider === "function") { // провайдер - функция
				result = provider();
			} else { // непосредственно данные
				result = provider;
			}
			if (result instanceof Promise) { // промис
				setter(null);
				result.then(res => {
					setter(res);
				});
			} else {
				setter(result);
			}
		},

		_getDataByProvider(field, property) {
			if (field && property && field.name) {
				const provider = field[property];
				this._setProviderData(provider, val => this.dataProviders[field.name] = val)
				return this.dataProviders[field.name];
			}
			return null;
		},

		// Errors
		_getFieldError(field) {
			if (field && field.name) {
				return this.errorsObj[field.name] ?? "";
			}
			return "";
		},
		_hasFieldError(field) {
			return !!this.errorsObj[field.name];
		},
		_setFieldError(field, msg) {
			if (field && field.name) {
				if (msg) {
					this.$set(this.errorsObj, field.name, msg);
				} else {
					this.$delete(this.errorsObj, field.name);
				}
				if (field.onError) {
					field.onError(field, msg, this.errorsObj);
				}
			}
		},
		_clearFieldError(field) {
			this._setFieldError(field, "");
			this.forEachSubform(subForm => subForm._clearFieldError(field));
		},

		_clearErrors() {
			this.errorsObj = {};
		},

		// Динамически проверяем изменения в required или валидации после первой валидации, если изменились на false - чистим ошибки в полях
		_clearErrorsOnUpdate() {
			if (this.isAlreadyValidated) {
				setTimeout(() => {
					this._checkValidateClearFields();
					this.forEachSubform(subForm => {
						subForm._checkValidateClearFields();
					});
				}, 100);
			}
		},

		// Methods

		/**
		 * Проверка заполнения формы.
		 * Значения в полях формы проверяются на соответствие правилам валидации.
		 * @param {*} fields Указание множества полей для проверки.
		 *     Если поля указаны, то проверяются только они.
		 *     Если не указаны, то проверяются все поля формы.
		 * @returns true - все поля заполнены корректно; false - есть ошибки.
		 */
		validate(fields) {
			let valid = true;
			// поля
			valid = this._checkValidateClearFields(fields);
			// вложенные блоки
			this.forEachSubform(subForm => {
				if (!subForm.validate(fields)) {
					valid = false;
				}
			});

			this.scrollIntoFirstError();
			this.isAlreadyValidated = true;
			return valid;
		},
		_checkValidateClearFields(fields) {
			let valid = true;
			this.flatFields
				.filter(ff => fields ? fields.includes(ff.name) : true)
				.filter(ff => this._isFieldVisible(ff))
				.forEach(ff => {
					const req = this._isFieldRequired(ff);
					if (!req) this._setFieldError(ff, "");
					if (ff.validator || req) {
						const val = this._getObjVal(ff);

						if (req && ff.type === 'boolean' && val === false) {
							this._setFieldError(ff, "Выберите обязательный чекбокс");

							return;
						}

						const validators = [
							req ? ofRequired() : null,
							Array.isArray(ff.validator)
								? ofRules(ff.validator) // массив правил
								: ff.validator // валидатор
						];
						const errs = validators
							.map(vv => {
								if (vv) {
									const res = vv(val, this.dataObj);
									if (res !== true) return res;
									return null;
								}
							})
							.filter(err => err);
						if (errs.length) {
							this._setFieldError(ff, errs.join(" "));
							valid = false;
						} else {
							this._setFieldError(ff, "");
						}
					}
				});
			return valid;
		},
		_isTableListFormat() {
			return this.tableListFormat;
		},
		resetValidation() {
			this._clearErrors();
		},
		scrollIntoFirstError() {
			if (this.disableErrorScroll) {
				return;
			}
			
			const names = Object.keys(this.errorsObj).filter(k => Boolean(this.errorsObj[k]));
			
			if (!names.length) {
				return;
			}
			const elements = names.map(name => window.document.getElementById(`form-col-${name}`));
			const offsets = elements.map(el => ({ el, offset: el.getBoundingClientRect().top + window.scrollY }));
			const first = offsets.reduce((p1, p2) => (p1?.offset < p2?.offset ? p1 : p2));
			const headerHeight = document.querySelector('#app .base-header')?.getBoundingClientRect().height || 0;

			if (this.scope === 'dialog') {
				const dialogScrollbar = document.getElementsByClassName('dialog-content__main--scrollbar-visible');

				if (dialogScrollbar.length) {
					dialogScrollbar[0].scrollTo({ top: first.offset - headerHeight, behavior: 'smooth' });
				}

				return;
			}
			window.scrollTo({ top: first.offset - headerHeight, behavior: 'smooth' });
		},
	},
};
</script>
